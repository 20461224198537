const $ = window.$;
import Modal from "../../../common/modules/modal";
import PlusOne from "../../../common/modules/plusone";

$(function() {

  // broadcast
  $(".js-broadcast-button").on("click", function() {
    if ($(this).data('raw') === undefined ||
      $(this).data('raw') == '') {
      return;
    }
    Modal.generate();
    Modal.buildBroadcastDialog(
      $(this).data('title'),
      $(this).data('subtitle'),
      $(this).data('raw')
    );
    Modal.show();
  });

  // tab
  $(".js-btn-seasonal").on("click", function() {
    $(".js-btn-seasonal").removeClass("on");
    $(this).addClass("on");

    const key = $(this).data("key");
    if (key === "all") {
      $(".js-seasonal-anime-list").show();
    } else {
      $(".js-seasonal-anime-list").hide();
      $(`.js-seasonal-anime-list-key-${key}`).show();
    }
  });

  $('.js-toggle-text').each((index, element) => {
    const displayableHeight = 180

    $(element).click((e) => {
      const button = $(e.target)
      const disp_area = button.siblings('.preline')

      if (disp_area.hasClass('expand')) {
        disp_area.removeClass('expand')
        $('i', button).addClass('fa-angle-down')
        $('i', button).removeClass('fa-angle-up')
      } else {
        disp_area.addClass('expand')
        $('i', button).removeClass('fa-angle-down')
        $('i', button).addClass('fa-angle-up')
      }
    })
  })

  //synopsis
  $(".js-seasonal-anime .js-synopsis").on({
    mouseenter: function() {
      $(this).addClass("block-scroll");
    },
    mouseleave: function() {
      $(this).removeClass("block-scroll");
    }
  });

  //Genres
  $(".js-seasonal-anime .js-genre").each(function(){
    const outerH = $(this).height();
    const innerH = $(this).find(".js-genre-inner").height();
    const id = $(this).attr("id");
    if (innerH > 24) {
      const html = _.template(`<span id="<%- id %>" class="btn-more-genre js-more-genre" data-height="<%- innerH %>" title="Click to view more genre"></span>`)({id: id, innerH: innerH});
      $(this).append(html);
      $(`.js-seasonal-anime .js-genre#${id} .js-more-genre`).on("click", function() {
        $(this).toggleClass("on");
        $(this).parent(".js-genre").toggleClass("open");
        if ($(this).parent(".js-genre").hasClass("open")) {
          $(this).parent(".js-genre").css({height:(innerH - 6)});
        } else {
          $(this).parent(".js-genre").css({height:12});
        }
      });
    }
  });

  //Licensors in Synopsis
  $(".js-seasonal-anime .licensors").each(function(){
    const licensors = $(this).attr("data-licensors").slice(0, -1).split(",");
    if (licensors[0].length > 0) {
      $(this).addClass("on").html(_.template(`<span class="fw-b">Licensor:</span><br><%- licensors %>`)({licensors: licensors.join(", ")}));
    }
  });

  //非表示ジャンル取得
  const getHideGenreIds = function() {
    const checkId = [];
    const hideId = [];

    //genre
    $(".js-seasonal-sort-order-block.js-genres li.js-btn-sort-order").each(function() {
      if ($(this).hasClass("crossed")) {
        hideId.push(parseInt($(this).attr("id")));
      }
      else if ($(this).hasClass("selected")) {
        checkId.push(parseInt($(this).attr("id")));
      }
    });

    return {
      check_ids: checkId,
      hide_ids: hideId,
    };
  }

  //Mylistフィルターチェック状況取得
  const getCheckMylistIds = function() {
    const checkId = [];

    const $btnMylist = $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order");
    $btnMylist.each(function(){
      if ($(this).hasClass("selected")) {
        checkId.push($(this).attr("id"));
      }
    });

    return {
      check_ids: checkId
    };
  }

  //ブロードキャストフィルター状況取得
  const getBroadcastFilterStatus = function() {
    if ($(".js-btn-show-broadcast").hasClass("on")) {
      return "on";
    }
    if ($(".js-btn-show-broadcast").hasClass("crossed")) {
      return "crossed";
    }
    return "";
  }

  // btn filter event
  if ($(".js-navi-seasonal")[0]) {

    let switchQuickFilter = function(e) {
      let genreIds = String($(e).data("id")).split(",").map(x => parseInt(x))

      // $(this).toggleClass("on");
      // crossed > none > on > crossed ...
      if ($(e).hasClass("crossed")) {
        $(e).removeClass("crossed")
        $(e).removeClass("on")
        $(e).children(".fa-check").hide();
        $(e).children(".fa-xmark").hide();

        // Why are element IDs used to identify the checkboxes...
        genreIds.forEach(function(id) {
          $(".js-seasonal-sort-order-block.js-genres li.js-btn-sort-order").each(function() {
            if ($(this).attr("id") == id) {
              $(this).removeClass("selected")
              $(this).removeClass("crossed")
              $(this).children(".fa-check").hide();
              $(this).children(".fa-xmark").hide();
            }
          })
        })
      } else if ($(e).hasClass("on")) {
        $(e).addClass("crossed")
        $(e).removeClass("on")
        $(e).children(".fa-check").hide();
        $(e).children(".fa-xmark").show();

        genreIds.forEach(function(id) {
          $(".js-seasonal-sort-order-block.js-genres li.js-btn-sort-order").each(function() {
            if ($(this).attr("id") == id) {
              $(this).removeClass("selected")
              $(this).addClass("crossed")
              $(this).children(".fa-check").hide();
              $(this).children(".fa-xmark").show();
            }
          })
        })
      } else {
        $(e).removeClass("crossed")
        $(e).addClass("on")
        $(e).children(".fa-check").show();
        $(e).children(".fa-xmark").hide();

        genreIds.forEach(function(id) {
          $(".js-seasonal-sort-order-block.js-genres li.js-btn-sort-order").each(function() {
            if ($(this).attr("id") == id) {
              $(this).addClass("selected")
              $(this).removeClass("crossed")
              $(this).children(".fa-check").show();
              $(this).children(".fa-xmark").hide();
            }
          })
        })
      }
    }

    let switchQuickFilterR18Kids = function(e) {
      let genreIds = String($(e).data("id")).split(",").map(x => parseInt(x))

      // crossed > none > on > crossed ...
      if ($(e).hasClass("crossed")) {
        $(e).removeClass("crossed")
        $(e).children(".fa-xmark").hide();

        // Why are element IDs used to identify the checkboxes...
        genreIds.forEach(function(id) {
          $(".js-seasonal-sort-order-block.js-genres li.js-btn-sort-order").each(function() {
            if ($(this).attr("id") == id) {
              $(this).removeClass("selected")
              $(this).removeClass("crossed")
              $(this).children(".fa-check").hide();
              $(this).children(".fa-xmark").hide();
            }
          })
        })
      } else {
        $(e).addClass("crossed")
        $(e).children(".fa-xmark").show();

        genreIds.forEach(function(id) {
          $(".js-seasonal-sort-order-block.js-genres li.js-btn-sort-order").each(function() {
            if ($(this).attr("id") == id) {
              $(this).removeClass("selected")
              $(this).addClass("crossed")
              $(this).children(".fa-check").hide();
              $(this).children(".fa-xmark").show();
            }
          })
        })
      }

      changeGenrePulldownColor();
    }

    //broadcast
    $(".js-btn-show-broadcast").on("click", function() {
      $(this).removeClass("crossed");
      if ($(this).hasClass("on")) {
        $(this).removeClass("on");
      } else {
        $(this).addClass("on");
      }
      fixCheckboxDisplay(this);
      const data = {
        id   : getHideGenreIds(),
        name : "genre_filter",
        type : "genres",
        url  : "/anime/season/config/filter.json",
      };
      submitConfig(data);
    });

    //r18
    $(".js-btn-show-r18").on("click", function() {
      // $(this).toggleClass("on");
      switchQuickFilterR18Kids(this)

      const data = {
        id   : getHideGenreIds(),
        name : "genre_filter",
        type : "genres",
        url  : "/anime/season/config/filter.json",
      };
      submitConfig(data);
    });

    // kids
    $(".js-btn-show-kids").on("click", function() {
      switchQuickFilterR18Kids(this)

      const data = {
        id: getHideGenreIds(),
        name: "genre_filter",
        type: "genres",
        url: "/anime/season/config/filter.json"
      }
      submitConfig(data)
    })

    //filter button
    $(".js-navi-seasonal .js-btn-show-sort").on("click", function() {
      $(document).off("click");
      const id = $(this).data("id");
      const $targetBlock = $(`.js-navi-seasonal .js-${id}`);
      if ( $targetBlock.css("display") === "block" ) {
        $(`.js-navi-seasonal .js-seasonal-sort-order-block.js-${id}`).hide();
        $(".js-navi-seasonal .js-btn-show-sort").removeClass("on");
      } else {
        $(".js-navi-seasonal .js-seasonal-sort-order-block").hide();
        $(".js-navi-seasonal .js-btn-show-sort").removeClass("on");
        $(`.js-navi-seasonal .js-seasonal-sort-order-block.js-${id}`).show();
        $(this).addClass("on");

        const showDialogInterval = setInterval(function() {
            closeSeasonalFilterDialog();
            clearInterval(showDialogInterval);
          }, 500);
      }
      if(id === "genres") {
        checkAllButton(id);
      }
    });
  }

  // Filterのプルダウンアイコンのカラーチェンジ
  const changeGenrePulldownColor = function() {
    const $genreFilter = $(".js-btn-show-sort.genres");
    let filterGenreIds = getHideGenreIds()

    if(filterGenreIds.check_ids.length > 0 || filterGenreIds.hide_ids.length > 0) {
      $genreFilter.addClass("filtered")
    } else {
      $genreFilter.removeClass("filtered")
    }
  }

  //領域外クリックで閉じる
  const closeSeasonalFilterDialog = function(id) {
    $(".js-navi-seasonal .js-seasonal-sort-order-block").on("click", function(e) {
      e.stopPropagation();
    });
    $(document).on("click", function() {
      $(".js-navi-seasonal .js-seasonal-sort-order-block").hide();
      $(".js-navi-seasonal .js-btn-show-sort").removeClass("on");
      checkAllButton("genres");
      $(document).off("click");
      return false;
    });
  }

  //ajax action for filter
  const submitConfig = function(obj) {
    const type     = obj.type;
    const id       = obj.id;
    const url      = obj.url;
    const name     = obj.name;
    const dataType = obj.dataType;

    let data = {};
    if (type != "broadcasts") {
      data[name] = id;
    }
    data.filter = {};
    data.filter.broadcast = getBroadcastFilterStatus();
    data = JSON.stringify(data);

    //loader
    if ($(`.js-seasonal-sort-order-block.js-${type} .js-ajax-loader`)[0]) {
      $(`.js-seasonal-sort-order-block.js-${type} .js-ajax-loader`).show();
    }

    //error message 削除
    if ($(`.js-seasonal-sort-order-block.js-${type} .js-error-message`)[0]) {
      $(`.js-seasonal-sort-order-block.js-${type} .js-error-message`).html("").hide();
    }

    $.ajax({
      url: url,
      type: "PUT",
      dataType: dataType,
      data: data,
      timeout: 10000,
      success: function() {
        //successなら下記を実行
        switch (type){
          case "genres":
            var $genreBtn = $(`.js-seasonal-sort-order-block.js-genres .js-btn-sort-order#${obj.cId}`);
            if ($genreBtn.hasClass("selected")) {
              $genreBtn.addClass("selected");
            } else {
              $genreBtn.removeClass("selected");
            }
            genreShowAnime();
            myListHideAnime();
            hideMiddleAd();
            break;
          case "broadcasts":
            genreShowAnime();
            hideMiddleAd();
            break;
          case "sort":
            window.location.reload();
            break;
          case "mylist":
            break;
        }
      },
      error: function(jqXHR) {
        const errorMessages = $.parseJSON(jqXHR.responseText).errors;
        const message = errorMessages[0].message ;
        const messageHtml__safe = _.template(`<span class=\"di-ib pb8\">Error: <%- message %></span>`)({message: message});
        switch (type){
          case "genres":
            $(".js-seasonal-sort-order-block.js-genres .js-error-message").html(messageHtml__safe).show();
            break;
          case "sort":
            $(".js-seasonal-sort-order-block.js-sort .js-error-message").html(messageHtml__safe).show();
            $(".js-seasonal-sort-order-block.js-sort .js-ajax-loader").hide();
            break;
          case "r18":
            $(".js-navi-seasonal .js-btn-show-r18").toggleClass("on");
            break;
          case "kids":
            $(".js-navi-seasonal .js-btn-show-kids").toggleClass("on");
            break;
        }
      },
      complete: function(data) {
        countVisibleAnime();
      }
    });
  }

  // filter R18/Kids(隠す専用)
  const filterHideKidsR18 = function() {
    filterShowHideKids();
    if (!$(".js-btn-show-r18").hasClass("on")) {
      $(".js-seasonal-anime.js-r18").hide();
    }
  }

  // filter Kids()
  const filterShowHideKids = function() {
    if (!$(".js-btn-show-kids").hasClass("on")) {
      $(".js-seasonal-anime.js-kids").hide();
    } else if ($(".js-btn-show-kids").hasClass("on") && $(".js-seasonal-sort-order-block.js-genres .js-btn-sort-order#All").hasClass("selected")) {
      //Genre ALL
      $(".js-seasonal-anime.js-kids").show();
    } else if ($(".js-btn-show-kids").hasClass("on") && !$(".js-seasonal-sort-order-block.js-genres .js-btn-sort-order#All").hasClass("selected")) {
      //Genreがkidsのみのもの
      const kidsID = $(".js-btn-show-kids").attr("data-id");
      $(".js-seasonal-anime.js-kids").each(function() {
        const genreID = $(this).attr("data-genre").split(",");
        if (genreID.length === 1 && genreID[0] === kidsID) {
          $(this).show();
        }
      });
    } else {
      $(".js-seasonal-anime.js-kids").show();
    }
    myListHideAnime();
  }

  // hideSeasonalAnime by My list(隠す専用)
  const myListHideAnime = function() {
    const $btnMylist = $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order");
    $btnMylist.each(function(){
      if (!$(this).hasClass("selected")) {
        const id = $(this).attr("id");
        const $seasonalAnime = $(`.js-categories-seasonal .js-seasonal-anime .js-anime-watch-status.${id}`).parents(".js-seasonal-anime");
        $seasonalAnime.hide();
      }
    });
  }

  // hideSeasonalAnime by Genre(隠す専用)
  const genreHideAnime = function() {
    genreShowAnime();
  }

  //showSeasonalAnime by Genre
  const genreShowAnime = function() {
    const $seasonalAnime = $(".js-seasonal-anime");
    const $btnSortOrder = $(".js-seasonal-sort-order-block.js-genres .btn-sort-order")
    const showIds = $btnSortOrder.map(function() {
      if ($(this).hasClass("selected")) {
        return $(this).attr("id");
      }
    }).get().join(",").split(",");
    const hideIds = $btnSortOrder.map(function() {
      if ($(this).hasClass("crossed")) {
        return $(this).attr("id");
      }
    }).get().join(",").split(",");

    $seasonalAnime.show();
    $seasonalAnime.each(function() {
      const item = $(this);
      const thisGenre = item.attr("data-genre").split(",");
      if (!testBroadcastVisibility(item)) {
        item.hide();
      }
      else {
        // genreが選択されていない場合はすべて表示
        item.show();
        for (var i=0;i<hideIds.length;i++) {
          // 空文字はskip
          if (hideIds[i].length === 0) {
            continue;
          }
          // hideIdsが含まれている場合は常に非表示
          if (thisGenre.indexOf(hideIds[i]) >= 0) {
            item.hide();
            break;
          }
        }
        for (var i=0;i<showIds.length;i++) {
          // 空文字はskip
          if (showIds[i].length === 0) {
            continue;
          }
          // showIdsは全て含まれている場合のみ表示
          if (thisGenre.indexOf(showIds[i]) === -1) {
            item.hide();
            break;
          }
        }
      }
    });
  }

  const testBroadcastVisibility = function(item) {
    if ($(".js-btn-show-broadcast").hasClass("on") &&
      item.data("broadcast-available") != 1) {
      return false;
    }
    if ($(".js-btn-show-broadcast").hasClass("crossed") &&
      item.data("broadcast-available") == 1) {
      return false;
    }
    return true;
  }

  // フィルターがかかっている場合は title_item の middle_ad を非表示
  const hideMiddleAd = function() {
    // Check genre filter
    const $btnGenres = $(".js-seasonal-sort-order-block.js-genres .btn-sort-order");
    let isGenreFiltered = false;
    $btnGenres.each(function() {
      if ($(this).hasClass("selected") || $(this).hasClass("crossed")) {
        isGenreFiltered = true
      }
    });

    // Check MyList filter
    const $btnMylist = $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order#All");
    let isMylistFiltered = false;
    if (!$btnMylist.hasClass("selected")) {
      isMylistFiltered = true;
    }

    // Check broadcast filter
    let isBroadcastFiltered = false;
    if (getBroadcastFilterStatus() != "") {
      isBroadcastFiltered = true;
    }

    const $middleAd = $(".js-middle_ad");
    if (isGenreFiltered || isMylistFiltered || isBroadcastFiltered) {
      $middleAd.hide();
    } else {
      $middleAd.show();
    }
  }

  //anime表示数カウント
  const countVisibleAnime = function() {
    const $seasonalAnime = $(".js-seasonal-anime");
    const visibleAnime = [];
    $seasonalAnime.each(function() {
      if ($(this).is(":visible")) {
        visibleAnime.push("visible");
      }
    });
    const totalCount = $seasonalAnime.length;
    const visibleCount = visibleAnime.length;
    const textAnimeCount = `${visibleCount}/${totalCount}`;
    $(".js-visible-anime-count").text(textAnimeCount);
  }

  //自動的に Allもチェックされる処理
  const checkAllButton = function(obj) {
    const $btnMylist = $(`.js-seasonal-sort-order-block.js-${obj} .js-btn-sort-order`);
    const selectedBtnAry = [];
    $btnMylist.each(function(){
      if ($(this).hasClass("selected") || $(this).hasClass("crossed")) {
        selectedBtnAry.push($(this).attr("id"));
      }
    });

    if (selectedBtnAry.length >= $btnMylist.length-1) {
      $btnMylist.addClass(function() {
        if (!$(this).hasClass("crossed")) {
          return "selected"
        }
      });
      $btnMylist.children(".fa-check").show();
    }

    if (obj === "genres") {
      // genresの場合、デフォルトで全てを選択されているわけではないので、選択されてない場合、緑としない。
      if (selectedBtnAry.length === 0) {
        $(`.js-btn-show-sort.${obj}`).removeClass("filtered");
      } else {
        $(`.js-btn-show-sort.${obj}`).addClass("filtered");
      }
    } else {
      // mylistなどの場合、デフォルトで全てが選択されているため、全て選択されている場合、緑としない。(-1はallの分を引く）
      if (selectedBtnAry.length >= ($btnMylist.length -1 )) {
        $(`.js-btn-show-sort.${obj}`).removeClass("filtered");
      } else {
        $(`.js-btn-show-sort.${obj}`).addClass("filtered");
      }
    }
  }

  // Fliterの選択エリア内の処理
  if ($(".js-seasonal-sort-order-block")[0]) {

    //close button
    $(".js-seasonal-sort-order-block .js-btn-close").on("click", function() {
      $(this).parents(".js-seasonal-sort-order-block").hide();
      $(".js-navi-seasonal .js-btn-show-sort").removeClass("on");
      checkAllButton("genres");
      $(document).off("click");
    });

    //mylist ボタン
    $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order").on("click", function() {
      const btnId = $(this).attr("id");
      if (btnId === "All") {
        const $seasonalAnime = $(".js-categories-seasonal .js-seasonal-anime");
        if ($(this).hasClass("selected")) {
          $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order").removeClass("selected");
          $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order").children(".fa-check").hide();
          $seasonalAnime.hide();
          $(".js-btn-show-sort.mylist").addClass("filtered");
        } else {
          $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order").addClass("selected");
          $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order").children(".fa-check").show();
          $seasonalAnime.show();
          genreHideAnime();
          $(".js-btn-show-sort.mylist").removeClass("filtered");
          hideMiddleAd();
        }
      } else {
        $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order#All").removeClass("selected");
        $(".js-seasonal-sort-order-block.js-mylist .js-btn-sort-order#All").children(".fa-check").hide();
        $(this).toggleClass("selected");
        $(this).children(".fa-check").toggle();
        checkAllButton("mylist");
        $(".js-categories-seasonal .js-seasonal-anime").show();
        genreHideAnime();
        myListHideAnime();
        hideMiddleAd();
      }
      countVisibleAnime();

      //Submit
      const data = {
        id   : getCheckMylistIds(),
        name : "mylist_filter",
        type : "mylist",
        url  : "/anime/season/config/mylist_filter.json",
      }
      submitConfig(data);
    });
  }

  // Sync R18/Kids checkbox with Genre Filter
  let syncFilterCheckbox = function(e) {
    let genreIds = String(e.data("id")).split(",").map(x => parseInt(x))

    let filterGenreIds = getHideGenreIds()

    let isHidden = true
    genreIds.forEach(function(id) {
      if (filterGenreIds.hide_ids.indexOf(id) === -1) {
        isHidden = false
      }
    })

    if (isHidden) {
      $(e).addClass("crossed")
      $(e).children(".fa-xmark").show();
    } else {
      $(e).removeClass("crossed")
      $(e).children(".fa-xmark").hide();
    }
  }

  let fixCheckboxDisplay = function(e) {
    if ($(e).hasClass("on")) {
      $(e).children(".fa-check").show();
      $(e).children(".fa-xmark").hide();
    } else if ($(e).hasClass("crossed")) {
      $(e).children(".fa-check").hide();
      $(e).children(".fa-xmark").show();
    } else {
      $(e).children(".fa-check").hide();
      $(e).children(".fa-xmark").hide();
    }
  }

  // genre ボタン
  $(".js-seasonal-sort-order-block.js-genres .js-btn-sort-order").on("click", function() {
    const btnId = $(this).attr("id");
    if ($(this).hasClass("selected")) {
      $(this).removeClass("selected");
      $(this).children(".fa-check").hide();
      $(this).addClass("crossed");
      $(this).children(".fa-xmark").show();
    }
    else if ($(this).hasClass("crossed")) {
      $(this).removeClass("crossed");
      $(this).children(".fa-xmark").hide();
    }
    else {
      $(this).addClass("selected");
      $(this).children(".fa-check").show();
    }

    syncFilterCheckbox($(".js-btn-show-kids").first())
    syncFilterCheckbox($(".js-btn-show-r18").first())

    changeGenrePulldownColor();

    //Submit
    const data = {
      id   : getHideGenreIds(),
      name : "genre_filter",
      type : "genres",
      url  : "/anime/season/config/filter.json",
      cId  : btnId
    }
    submitConfig(data);
  });

  // ClearAllボタン
  $(".js-seasonal-sort-order-block.js-genres .clear-all-btn").on("click", function() {
    const btnId = $(".js-seasonal-sort-order-block.js-genres .js-btn-sort-order").attr("id");
    $(this).siblings(".selected").removeClass("selected");
    $(this).siblings().children(".fa-check").hide();
    $(this).siblings(".crossed").removeClass("crossed");
    $(this).siblings().children(".fa-xmark").hide();
    syncFilterCheckbox($(".js-btn-show-kids").first())
    syncFilterCheckbox($(".js-btn-show-r18").first())
    changeGenrePulldownColor();

    //Submit
    const data = {
      id   : getHideGenreIds(),
      name : "genre_filter",
      type : "genres",
      url  : "/anime/season/config/filter.json",
      cId  : btnId
    }
    submitConfig(data);
  });

  // sort ボタン
  $(".js-seasonal-sort-order-block.js-sort .js-btn-sort-order").on("click", function() {
    $(".js-seasonal-sort-order-block.js-sort .js-btn-sort-order").removeClass("selected");
    $(".js-seasonal-sort-order-block.js-sort .js-btn-sort-order").children(".fa-solid.fa-circle").hide();
    $(this).addClass("selected");
    $(this).children(".fa-solid.fa-circle").show();


    //Submit
    const data = {
      id   : $(this).attr("id"),
      name : "sort_by",
      type : "sort",
      url  : "/anime/season/config/sort.json",
      dataType : "html",
    }
    submitConfig(data);
  });


  // add Episode number
  if ($(".js-btn-add-episode")[0]) {
    $(".js-btn-add-episode").on("click", function(){
      const epsID      = $(this).data("id");
      const epsNum     = parseInt($(`.js-episode-num[data-id=${epsID}]`).first().data('num'));
      let epsTotal   = parseInt($(this).data("eps"));

      epsTotal = epsTotal ? epsTotal : 9999;

      if (epsTotal > epsNum) {
        // Clear preparing plus one dialog
        PlusOne.clearVoteDialog();
        $(`.js-btn-add-episode[data-id=${epsID}] i.fa-solid`).removeClass('fa-circle-plus').addClass('fa-spinner fa-spin');
        const newEpsNum = epsNum+1;
        $.ajax({
          url: "/includes/ajax.inc.php?t=79",
          type: "POST",
          data: {
                  anime_id:epsID,
                  ep_val:newEpsNum
                },
          datatype: "html",
          timeout: 10000,
          cache: false,
          success: function(XMLHttpRequest, textStatus, errorThrown) {
            $(`.js-episode-num[data-id=${epsID}]`).text(newEpsNum).data('num', newEpsNum);
            $(`.js-btn-add-episode[data-id=${epsID}] i.fa-solid`).removeClass('fa-spinner fa-spin').addClass('fa-circle-plus');
            if (epsTotal <= newEpsNum) {
              $(`.js-btn-add-episode[data-id=${epsID}]`).hide();
            }
            if(errorThrown.responseText === "This anime has not been approved yet.") {
              $(`.js-btn-add-episode[data-id=${epsID}]`).hide();
            }
            // Show plus one dialog
            PlusOne.showAnimeVoteDialog(epsID, newEpsNum);
          }
        });
      }
    })
  }

  // add Chapter number
  if ($(".js-btn-add-chapter")[0]) {
    $(".js-btn-add-chapter").on("click", function(){
      const epsID      = $(this).data("id");
      const epsNum     = parseInt($(`.js-chapter-num[data-id=${epsID}]`).first().data('num'));
      let epsTotal   = parseInt($(this).data("chps"));

      epsTotal = epsTotal ? epsTotal : 9999;

      if (epsTotal > epsNum) {
        // Clear preparing plus one dialog
        PlusOne.clearVoteDialog();
        $(`.js-btn-add-chapter[data-id=${epsID}] i.fa-solid`).removeClass('fa-circle-plus').addClass('fa-spinner fa-spin');
        const newEpsNum = epsNum+1;
        $.ajax({
          url: "/includes/ajax.inc.php?t=80",
          type: "POST",
          data: {
                  manga_id:epsID,
                  chp_val:newEpsNum
                },
          datatype: "html",
          timeout: 10000,
          cache: false,
          success: function(XMLHttpRequest, textStatus, errorThrown) {
            $(`.js-chapter-num[data-id=${epsID}]`).text(newEpsNum).data('num', newEpsNum);
            $(`.js-btn-add-chapter[data-id=${epsID}] i.fa-solid`).removeClass('fa-spinner fa-spin').addClass('fa-circle-plus');
            if (epsTotal <= newEpsNum) {
              $(`.js-btn-add-chapter[data-id=${epsID}]`).hide();
            }
            if(errorThrown.responseText === "This manga has not been approved yet.") {
              $(`.js-btn-add-chapter[data-id=${epsID}]`).hide();
            }
            // Show plus one dialog
            PlusOne.showMangaVoteDialog(epsID, newEpsNum);
          }
        });
      }
    })
  }

  //get Origin image size
  const getImageSize = function(ele) {
    const $anime = $(".js-seasonal-anime");
    $anime.each( function() {
        const img = new Image();
        img.src = $(this).find(".image").css("background-image").replace( /(url\(|\)|")/g, "" );
        const width = img.width;
        const height = img.height;
        if (width > height) {
          $(this).find(".image").css({"background-size":"contain"});
        }
    });
  }


  // menu fix
  const seasonalMenuFixed = function() {
    const $wrapper = $(".wrapper")[0].getBoundingClientRect();
    const posH = $(this).scrollTop();
    const contentH = $("#content").height();
    const browserH = document.documentElement.clientHeight;
    if ($(".sUaidzctQfngSNMH_pc_top").exists()) {
      var headerH = 142 + $(".sUaidzctQfngSNMH_pc_top").height();
    } else {
      var headerH = 142;
    }
    if ($(".top_signup").exists()) {
      headerH = headerH + $(".top_signup").height();
    }
    //menu
    const $navi = $(".js-navi-seasonal");
    if ($navi.exists()) {
      const pTop = $navi.height();
      if (posH > headerH) {
        $(".js-navi-seasonal").addClass("fixed");
        $(".js-navi-seasonal").css({left:$wrapper.left+1});
        $(".js-categories-seasonal").css({"padding-top": pTop});
      }
      if (posH < headerH) {
        $(".js-navi-seasonal").removeClass("fixed");
        $(".js-navi-seasonal").css({left:0});
        $(".js-categories-seasonal").css({"padding-top": 0});
      }
    }
    //button top
    if (posH > 113 && contentH - posH > browserH - headerH) {
      $(".js-btn-top").css({left:$wrapper.left+1020}).show();
    }
    if (posH < headerH || contentH - posH < browserH - headerH) {
      $(".js-btn-top").hide();
    }
  }

 $(".js-btn-top").on("click", function() {
   $( "html,body" ).animate({scrollTop:0}) ;
 });

  if ($(".page-common.season")[0]) {
    $(window).on("scroll resize", seasonalMenuFixed);
    $(window).on("load", function(){
      seasonalMenuFixed();
      getImageSize();
    });
  }

  // on load page
  if ($(".page-common.season")[0]) {
    checkAllButton("genres");
    checkAllButton("mylist");
    genreShowAnime();
    syncFilterCheckbox($(".js-btn-show-kids").first())
    syncFilterCheckbox($(".js-btn-show-r18").first())
    fixCheckboxDisplay($(".js-btn-show-broadcast").first())
    myListHideAnime();
    hideMiddleAd();
    countVisibleAnime();
  }
});
